
  export default {
    name: `CandidateScreeningsPage`,
    middleware({ $gates, store, route, redirect }) {
      const roles = store.getters[`menu/getRoleFromUrl`](route.path)
      if (!roles || (!!roles && !$gates.hasRole(roles))) {
        redirect(`/`)
      }
      if (store?.$auth?.user?.candidate_screenings?.length === 1) {
        redirect(`/screenings/${store.$auth.user.candidate_screenings[0]}`)
      }
    },
    data() {
      return {
        loadedScreening: null,
        selectedScreening: null,
        headers: [
          { text: this.$t(`pages.candidate.job_title`), value: `job_title`, filterable: true },
          { text: this.$t(`pages.candidate.company`), value: `company_name`, filterable: true },
          { text: this.$t(`pages.candidate.launched_at`), value: `launch_date`, filterable: true }
        ],
        search: ``
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$nuxt.$loading.start()
      })
      this.getScreenings()
        .then((data) => {
          console.log(data)
          if (!!data) {
            if (data.length === 1) {
              this.$router.replace({ path: `/screenings/` + data[0].id })
            } else {
              this.loadedScreening = data
                .map((s) => {
                  s.status = !!s.status ? s.status : { description: ` ` }
                  s.result = !!s.result ? s.result : { description: ` ` }
                  return s
                })
                .sort((a, b) => this.$moment(a.launch_date).unix() - this.$moment(b.launch_date).unix())
            }
          }
        })
        .finally(() => {
          this.$nextTick(function () {
            this.$nuxt.$loading.finish()
          })
        })
    },
    methods: {
      getScreenings() {
        return this.$api.screenings.me()
      },
      openScreening(screening) {
        this.$router.push(`/screenings/${screening.id}`)
      }
    }
  }
